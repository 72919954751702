<template>
  <v-container>
    <eagle-form :form-key="formKey"></eagle-form>
  </v-container>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './providerWebConfig'
export default {
  mixins: [formMixin],
  data: () => ({
    formKey: 'provider-web-form',
    meta: {},
    isSetted : false
  }),
  mounted() {
    
  },
  methods: {
    async beforeFormInit() {
      await Promise.all([
        this.loadProductList()
      ])
    },
    async loadProductList() {
      let result = null
      try {
        const params = {
          page: 1,
          per: 500,
          filter: {},
          sort: {
              created_at: 'desc'
          },
          status: 1,
        }
        result = await this.$api.collection.productApi.index(this.providerId, params)
      } catch (error) {
        console.warn(error)
      }
      this.meta.productList = result.data
    },
    async customDefaultData() {
      let defaultFormData = {
        logo: null,
        ig: '',
        fb: '',
        youtube: '',
        news: '',
        about_us: '',
        contact_us: '',
        main_banner: [],
        sub_banner: [],
        hot_product: [],
      }
      return defaultFormData
    },
    async readApi(target) {
      console.log('readApi')
      try {
        console.log('return1:')
        const result = await this.$api.collection.providerApi.getWebsite(this.providerId)
        return this.getComputedReadData(result)
      } catch (error) {
        return this.customDefaultData()
      }
    },
    getComputedReadData(product) {
      product.hot_product = product.hot_product.map(v => v.id)
      product.main_banner = product.banner.filter(v => v.type == 'MAIN').map(v => {
        let returnObj = Object.assign(v.image[0], {
          link: v.link,
          banner_id: v.id.toString()
        })
        return returnObj
      })
      product.sub_banner = product.banner.filter(v => v.type == 'SECOND').map(v => {
        let returnObj = Object.assign(v.image[0], {
          link: v.link,
          banner_id: v.id.toString()
        })
        return returnObj
      })
      return product
    },
    async updateApi(target, formData) {
      const {
        logo,
        ig,
        fb,
        youtube,
        news,
        about_us,
        contact_us,
      } = formData
      const Main = formData.main_banner.map(v => ({
        id: v.banner_id,
        link: v.link || '',
        image: [v],
        type: 'MAIN'
      }))
      const Second = formData.sub_banner.map(v => ({
        id: v.banner_id,
        link: v.link || '',
        image: [v],
        type: 'SECOND'
      }))
      const Hot = this.meta.productList.filter(v => {
        return formData.hot_product.includes(v.id)
      })
      const requestData = {
        logo,
        ig,
        fb,
        youtube,
        news,
        about_us,
        contact_us,
        banner: [...Main, ...Second],
        hot_product: Hot
      }
      const result = await this.$api.collection.providerApi.updateWebsite(this.providerId, requestData)
      return this.getComputedReadData(result)
    },
    // async deleteApi(target) {
    //   return await this.$api.collection.productApi.delete(this.providerId, target)
    // },
    getFormConfig() {
      return formConfig
    },
    setParams(formData) {
      if(formData) formData.provider_id = this.providerId
      return formData;
    },
    // async afterSaveSuccessfully(result) {
    //   try {
    //     await this.$api.collection.productApi.bindMenu(this.providerId, result.id, this.formData.menus)
    //   } catch(error) {
    //     console.warn(`綁定商品、商品分類api發生意外錯誤`)
    //     console.warn(error)
    //   }
    // },
  },
  computed: {
    providerId() {
      return this.$store.getters['member/providerId']
    },
  },

}
</script>

<style lang="sass" type="text/sass" scoped></style>
