import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'

class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
      
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        { label: 'page.product', route: { name: 'product' } },
      ],
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.product',
      dataAction: {
        delete: false,
        back:false
      },
      defaultData: this._defaultData,
      metaAction: {},
      blocks: [
        {
          title: '網站設定',
          data: {
            website_link: {
              component: () => import('./websiteLink.vue'),
            },
            logo: {
              label: 'Icon上傳',
              type: 'photo',
              photoType: 'product',
              multiple: true,
              simpleMode: true,
              total: 1,
              isDraggable: false,
              grid: { xl: 12, lg: 12, md: 12 },
              hasChanged: ({originalData, data, photoListEqual}: FormDataChangeHelperInterface) => {
                return photoListEqual(originalData, data) === false
              },
            },
            main_banner: {
              label: '主banner：建議上傳1920*768(比例5:2）',
              type: 'photo',
              photoType: 'product',
              multiple: true,
              simpleMode: true,
              linkable: true,
              useOrigin: true,
              total: 100,
              isDraggable: false,
              grid: { xl: 12, lg: 12, md: 12 },
              hasChanged: ({originalData, data, photoListEqual}: FormDataChangeHelperInterface) => {
                return photoListEqual(originalData, data) === false
              },
            },
            sub_banner: {
              label: '副banner：建議上傳1080*608（比例16:9）',
              type: 'photo',
              photoType: 'product',
              multiple: true,
              simpleMode: true,
              linkable: true,
              useOrigin: true,
              total: 100,
              isDraggable: false,
              grid: { xl: 12, lg: 12, md: 12 },
              hasChanged: ({originalData, data, photoListEqual}: FormDataChangeHelperInterface) => {
                return photoListEqual(originalData, data) === false
              },
            },
            social_media: {
              component: () => import('./socialMedia.vue'),
            },
            hot_product: {
              label: '熱門商品',
              type: 'selection',
              multiple: true,
              clearable: true,
              options: (formInfo: any) => {
                console.log('formInfo:', formInfo)
                if (!formInfo.formMeta) return []
                return formInfo.formMeta.productList.map((product: any) => {
                  return { text: product.name, value: product.id }
                })
              },
              hasChanged({originalData, data, bothEmpty}: FormDataChangeHelperInterface) {
                if(bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
            news: {
              label: '最新消息',
              type: 'textarea',
              maxlength: 2500,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            about_us: {
              label: '關於我們',
              type: 'textarea',
              maxlength: 2500,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            contact_us: {
              label: '聯絡我們',
              type: 'textarea',
              maxlength: 2500,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
          },
        },
      ],
    }
  }
}

export default new formConfig()
